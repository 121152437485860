import { api } from '.';
import { ConfigPrice } from '../interface/configPrice';

export const GetConfigPrices = async (options: { token: string }) => {
  const { token } = options;
  const response = await api({
    method: 'GET',
    url: `/config-prices`,
    headers: {
      'access-token': token
    }
  });
  return response;
};

export const AddConfigPrice = async (options: {
  token: string;
  data: Pick<ConfigPrice, 'price'>;
}) => {
  const { token, data } = options;
  const response = await api({
    method: 'POST',
    url: `/config-prices/add`,
    headers: {
      'access-token': token
    },
    data
  });
  return response;
};

export const RemoveConfigPrice = async (options: { token: string; idConfigPrice: string }) => {
  const { token, idConfigPrice } = options;

  const response = await api({
    method: 'DELETE',
    url: `/config-prices/${idConfigPrice}`,
    headers: {
      'access-token': token
    }
  });
  return response;
};

export const RemoveAllConfigPrice = async (options: { token: string }) => {
  const { token } = options;

  const response = await api({
    method: 'DELETE',
    url: `/config-prices/all`,
    headers: {
      'access-token': token
    }
  });
  return response;
};
