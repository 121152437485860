import { TypeParticipantPeriod } from './period';

export enum SelectModules {
  BAR = 'BAR',
  RESTAURANTE = 'RESTAURANTE'
}

export type Modules = SelectModules.BAR | SelectModules.RESTAURANTE;

export type ValueQr = {
  type: TypeParticipantPeriod;
  id: string;
};
