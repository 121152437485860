import { AxiosError } from 'axios';

export const HandleErrorAxios = (error: AxiosError) => {
  if (error.request?.response) {
    const status = JSON.parse(error.request.response).status;

    return status;
  }
  return error.message;
};
