export enum SelectRolName {
  REPRESENTATIVE = 'REPRESENTATIVE',
  ADMIN = 'ADMIN',
  EMPLOYEE = 'EMPLOYEE'
}

export type RolName = SelectRolName.REPRESENTATIVE | SelectRolName.ADMIN | SelectRolName.EMPLOYEE;

export enum SelectUserPlatform {
  ALL = 'ALL',
  BAR = 'BAR',
  RESTAURANT = 'RESTAURANT'
}

export type UserPlatform =
  | SelectUserPlatform.ALL
  | SelectUserPlatform.BAR
  | SelectUserPlatform.RESTAURANT;

export enum SelectUserGender {
  OTHER = 'OTHER',
  MAN = 'MAN',
  WOMEN = 'WOMEN'
}

export type UserGender = SelectUserGender.OTHER | SelectUserGender.MAN | SelectUserGender.WOMEN;

export type User = {
  userID: string;
  email: string;
  name: string | null;
  lastName: string | null;
  displayName: string;
  phoneNumber: string | null;
  isActive: boolean;
  birthday: string | null;
  avatarURL: string | null;
  password: string;
  gender: UserGender | null;
  idRol: string;
  credit: number | string;
  platform: UserPlatform;
  createdAt: string;
  updatedAt: string;
  rolName: RolName;
};

export type LoginUser = {
  user: User;
  token: string;
  refreshToken: string;
};

export type HashPassword = {
  digest: string;
  algorithm: string;
};
