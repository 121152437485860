import { Navigate, useRoutes } from 'react-router-dom';
import { NotFound } from '../views/not-found';
import { toast } from 'react-toast';
import { LazyExoticComponent, Suspense, lazy, useContext } from 'react';
import { MeContext } from '../context/me';
import { RolName, SelectRolName, User } from '../interface/user';

// import dynamic
const HomeView = lazy(() => import('../views/home'));
const LoginView = lazy(() => import('../views/login'));
const RepresentativesView = lazy(() => import('../views/representatives/index'));
const RepresentativeDetailsView = lazy(() => import('../views/representatives/details'));
const ProfileView = lazy(() => import('../views/profile'));
const InvitesView = lazy(() => import('../views/invites/index'));
const StudentsView = lazy(() => import('../views/students/index'));
const StudentDetailsView = lazy(() => import('../views/students/details'));
const ConsumptionsView = lazy(() => import('../views/consumption/index'));
const EmployeesView = lazy(() => import('../views/employees/index'));
const AdminsView = lazy(() => import('../views/admins/index'));
const ActivitiesView = lazy(() => import('../views/activities/index'));
const PeriodsView = lazy(() => import('../views/period/index'));
const PeriodDetailsView = lazy(() => import('../views/period/details'));
const SaleRestaurantView = lazy(() => import('../views/saleRestaurant'));

const routes = (me: User | null) => {
  const pathname = window.location.pathname;

  const PathAuth = (options: {
    Component: LazyExoticComponent<() => JSX.Element>;
    rolPermit?: RolName[];
  }) => {
    const { Component, rolPermit } = options;
    const isFieldEmptyUser =
      !me?.name || !me?.lastName || !me?.phoneNumber || !me?.birthday || !me?.gender;

    if (me && isFieldEmptyUser && pathname !== '/profile') {
      toast.hideAll();
      toast.warn('Complete los datos de su perfil');
      return <Navigate to={`/profile?edit=true`} />;
    }

    if (rolPermit?.length) {
      if (me && !rolPermit.includes(me.rolName)) {
        return <Navigate to={`/profile`} />;
      }
    }

    return me ? <Component /> : <Navigate to={`/login?redirect=${pathname}`} />;
  };

  const NotPathAuth = (Component: LazyExoticComponent<() => JSX.Element>) => {
    return <Component />;
  };

  return [
    {
      path: '/',
      children: [
        { path: '/login', element: NotPathAuth(LoginView) },
        {
          path: '/',
          element: PathAuth({
            Component: HomeView,
            rolPermit: [SelectRolName.ADMIN, SelectRolName.EMPLOYEE]
          })
        },
        {
          path: '/activities',
          element: PathAuth({
            Component: ActivitiesView,
            rolPermit: [SelectRolName.ADMIN, SelectRolName.EMPLOYEE]
          })
        },
        {
          path: '/periods',
          element: PathAuth({
            Component: PeriodsView,
            rolPermit: Object.values(SelectRolName)
          })
        },
        {
          path: '/periods/:periodID',
          element: PathAuth({
            Component: PeriodDetailsView,
            rolPermit: Object.values(SelectRolName)
          })
        },
        {
          path: '/sale-restaurant',
          element: PathAuth({
            Component: SaleRestaurantView,
            rolPermit: [SelectRolName.ADMIN, SelectRolName.EMPLOYEE]
          })
        },
        {
          path: '/invites',
          element: PathAuth({ Component: InvitesView, rolPermit: [SelectRolName.ADMIN] })
        },
        {
          path: '/profile',
          element: PathAuth({ Component: ProfileView, rolPermit: Object.values(SelectRolName) })
        },
        {
          path: '/representatives',
          element: PathAuth({
            Component: RepresentativesView,
            rolPermit: [SelectRolName.ADMIN, SelectRolName.EMPLOYEE]
          })
        },
        {
          path: '/representatives/:userID',
          element: PathAuth({
            Component: RepresentativeDetailsView,
            rolPermit: [SelectRolName.ADMIN, SelectRolName.EMPLOYEE]
          })
        },
        {
          path: '/students',
          element: PathAuth({
            Component: StudentsView,
            rolPermit: [SelectRolName.ADMIN, SelectRolName.EMPLOYEE]
          })
        },
        {
          path: '/students/:studentID',
          element: PathAuth({
            Component: StudentDetailsView,
            rolPermit: Object.values(SelectRolName)
          })
        },
        {
          path: '/consumptions',
          element: PathAuth({
            Component: ConsumptionsView,
            rolPermit: [SelectRolName.ADMIN, SelectRolName.EMPLOYEE]
          })
        },
        {
          path: '/employees',
          element: PathAuth({ Component: EmployeesView, rolPermit: [SelectRolName.ADMIN] })
        },
        {
          path: '/admins',
          element: PathAuth({ Component: AdminsView, rolPermit: [SelectRolName.ADMIN] })
        },
        { path: '/404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    }
  ];
};

const App = () => {
  const { me, loadingUser } = useContext(MeContext);

  return (
    <>
      {loadingUser ? (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="spinner"></div>
          <span className="text-3xl p-3 text-white">Cargando Datos...</span>
        </div>
      ) : (
        <Suspense fallback={<div />}>{useRoutes(routes(me))}</Suspense>
      )}
    </>
  );
};

export default App;
