import * as axios from 'axios';

// const apiDev = 'http://localhost:9000';
const apiProd = 'https://server-bar-lda.onrender.com';

export const BASE_API = apiProd;
export const NAME_TOKEN_COOKIE = 'access-token';
export const NAME_MODULE_COOKIE = 'module-padimalu';
export const AVATAR_DEFAULT =
  'https://res.cloudinary.com/cici/image/upload/v1619668102/util/avatar-default_ux9wip.svg';
export const NAME_LOGO = 'logo-bar-lda.jpeg';
export const LOGO_URL_API = `${BASE_API}/static/img/${NAME_LOGO}`;
export const BASE_API_STATIC = `${BASE_API}/static`;

export const api = axios.default.create({
  baseURL: `${BASE_API}/api`
});

api.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
api.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
