/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
  useEffect,
  useContext
} from 'react';

import { toast } from 'react-toast';
import { HandleErrorAxios } from '../helpers/errors';
import { ConfigPrice } from '../interface/configPrice';
import { GetConfigPrices } from '../api/configPrice';
import { MeContext } from './me';
import { SelectRolName } from '../interface/user';

interface Props {
  children: ReactNode;
}

interface Values {
  prices: ConfigPrice[];
  setPrices: Dispatch<SetStateAction<ConfigPrice[]>>;
}

export const PricesContext = createContext<Values>({
  prices: [],
  setPrices: () => []
});

export const PricesContextProvider = ({ children }: Props) => {
  const { me, token } = useContext(MeContext);
  const [prices, setPrices] = useState<ConfigPrice[]>([]);

  const Values: Values = {
    prices,
    setPrices
  };

  const FetchConfigPrices = async () => {
    try {
      const { configPrices } = await (await GetConfigPrices({ token })).data;
      setPrices(configPrices || []);
    } catch (error: any) {
      toast.error(HandleErrorAxios(error));
    }
  };

  useEffect(() => {
    if (me && [SelectRolName.ADMIN, SelectRolName.EMPLOYEE].includes(me.rolName)) {
      FetchConfigPrices();
    }
  }, [me]);

  return <PricesContext.Provider value={Values}>{children}</PricesContext.Provider>;
};
