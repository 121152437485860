/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
  useEffect
} from 'react';

import useCookie from 'react-use-cookie';

import { NAME_MODULE_COOKIE, NAME_TOKEN_COOKIE } from '../api';
import { GetMeUser } from '../api/user';
import { User } from '../interface/user';
import { toast } from 'react-toast';
import { HandleErrorAxios } from '../helpers/errors';
import { SelectModules } from '../interface/utils';

interface Props {
  children: ReactNode;
}

interface Values {
  isBar: boolean;
  setIsBar: Dispatch<SetStateAction<boolean>>;
  token: string;
  setToken: Dispatch<SetStateAction<string>>;
  me: User | null;
  setMe: Dispatch<SetStateAction<User | null>>;
  startSale: boolean;
  setStartSale: Dispatch<SetStateAction<boolean>>;
  loadingUser: boolean;
  setLoadingUser: Dispatch<SetStateAction<boolean>>;
}

export const MeContext = createContext<Values>({
  isBar: false,
  setIsBar: () => false,
  token: '',
  setToken: () => '',
  me: null,
  setMe: () => null,
  startSale: false,
  setStartSale: () => false,
  loadingUser: false,
  setLoadingUser: () => false
});

export const MeContextProvider = ({ children }: Props) => {
  const [userTokenCookie] = useCookie(NAME_TOKEN_COOKIE);
  const [moduleAppCookie, setModuleAppCookie] = useCookie(NAME_MODULE_COOKIE);
  const [token, setToken] = useState<string>(userTokenCookie || '');
  const [me, setMe] = useState<User | null>(null);
  const [startSale, setStartSale] = useState<boolean>(false);
  const [isBar, setIsBar] = useState<boolean>(
    !moduleAppCookie || moduleAppCookie === SelectModules.BAR
  );
  const [loadingUser, setLoadingUser] = useState<boolean>(true);

  const Values: Values = {
    isBar,
    setIsBar,
    token,
    setToken,
    me,
    setMe,
    startSale,
    setStartSale,
    loadingUser,
    setLoadingUser
  };

  const FetchMe = async (accesskey: string) => {
    setLoadingUser(true);
    try {
      const data = await (await GetMeUser({ token: accesskey })).data;
      setMe(data.me);
    } catch (error: any) {
      toast.error(HandleErrorAxios(error));
    } finally {
      setLoadingUser(false);
    }
  };

  useEffect(() => {
    if (token) FetchMe(token);
    else setLoadingUser(false);
  }, [token]);

  useEffect(() => {
    if (!moduleAppCookie) setModuleAppCookie(SelectModules.BAR);
  }, [moduleAppCookie]);

  return <MeContext.Provider value={Values}>{children}</MeContext.Provider>;
};
