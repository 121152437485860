import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './router';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { MeContextProvider } from './context/me';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement
} from 'chart.js';
import { PricesContextProvider } from './context/prices';
import { ToastContainer } from 'react-toast';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <>
    <MeContextProvider>
      <PricesContextProvider>
        <BrowserRouter>
          <ToastContainer delay={7000} position="top-center" />
          <App />
        </BrowserRouter>
      </PricesContextProvider>
    </MeContextProvider>
  </>
);

reportWebVitals();
