import { api } from '.';
import { AddQueryByField } from '../helpers/array';
import { HashPassword, RolName, User } from '../interface/user';

export const GetMeUser = async (options: { token: string }) => {
  const response = await api({
    method: 'GET',
    url: '/users/me',
    headers: {
      'access-token': options.token
    }
  });
  return response;
};

export const GetUser = async (options: { token: string; userID: string }) => {
  const { token, userID } = options;

  const response = await api({
    method: 'GET',
    url: `/users/${userID}`,
    headers: {
      'access-token': token
    }
  });
  return response;
};

export const GetListUsers = async (options: {
  token: string;
  rolName?: RolName;
  find?: string;
  isActive?: string;
  page?: number;
}) => {
  const response = await api({
    method: 'GET',
    url: `/users${AddQueryByField({ ...options, token: null })}`,
    headers: {
      'access-token': options.token
    }
  });
  return response;
};

export const LoginUser = async (data: Pick<User, 'email'> & { password: HashPassword }) => {
  const response = await api({
    method: 'POST',
    url: '/users/login',
    data
  });
  return response;
};

export const VerifyUser = async (
  data: Pick<User, 'email'> & { password: HashPassword; token: string }
) => {
  const response = await api({
    method: 'POST',
    url: '/users/verify',
    data
  });
  return response;
};

export const ResetMfaUser = async (data: Pick<User, 'email'> & { password: HashPassword }) => {
  const response = await api({
    method: 'POST',
    url: '/users/reset-mfa',
    data
  });
  return response;
};

export const UpdateMeUser = async (options: {
  data: Partial<Pick<User, 'displayName' | 'birthday' | 'isActive' | 'phoneNumber' | 'gender'>>;
  token: string;
}) => {
  const { data, token } = options;

  const response = await api({
    method: 'PUT',
    url: '/users/me',
    headers: {
      'access-token': token
    },
    data
  });
  return response;
};

export const UpdateActiveUser = async (options: {
  data: Partial<Pick<User, 'isActive'>>;
  userID: string;
  token: string;
}) => {
  const { data, userID, token } = options;

  const response = await api({
    method: 'PUT',
    url: `/users/active/${userID}`,
    headers: {
      'access-token': token
    },
    data
  });
  return response;
};

export const AddCreditUser = async (options: {
  data: Pick<User, 'credit' | 'userID'>;
  token: string;
}) => {
  const { data, token } = options;

  const response = await api({
    method: 'POST',
    url: '/users/credit',
    headers: {
      'access-token': token
    },
    data
  });
  return response;
};

export const RemoveMeUser = async (options: { token: string }) => {
  const { token } = options;
  const response = await api({
    method: 'DELETE',
    url: '/users/me',
    headers: {
      'access-token': token
    }
  });
  return response;
};

export const RemoveUser = async (options: { token: string; userID: string }) => {
  const { token, userID } = options;
  const response = await api({
    method: 'DELETE',
    url: `/users/${userID}`,
    headers: {
      'access-token': token
    }
  });
  return response;
};
