import { RolName } from '../interface/user';
import { Modules } from '../interface/utils';
import { itemsMenu } from './aside';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const AddQueryByField = (options: Record<string, any>) => {
  const ArrayByField = Object.entries(options);

  let countQuery = 0;
  const resultArray = ArrayByField.map(item => {
    if (!item[1]) return null;

    const query = `${item[0]}=${item[1]}`;

    if (!countQuery) {
      countQuery += 1;
      return `?${query}`;
    }

    countQuery += 1;
    return `&${query}`;
  });

  return resultArray
    .filter(res => res !== null)
    .join()
    .replace(/,/g, '');
};

export const createArrayOfValues = (n: number) => {
  return Array.from({ length: n }, (_, i) => i + 1);
};

export const removeItemOnce = (options: { arr: any[]; index: number }) => {
  const { arr, index } = options;
  return arr.filter((value, indexArr) => indexArr !== index);
};

export const filterItemsMenuByRol = (options: { rol: RolName; module: Modules }) => {
  const { rol, module } = options;
  return itemsMenu.filter(item => item.roles.includes(rol) && item.modules.includes(module));
};
